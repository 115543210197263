/**
 * TODO: after every updrage of rc-tooltip, double check if that error still occurs
 * version of rc-tooltip during last check: ^6.4.0
 */
const isFindDOMNodeErrorFromRCTooltip = (args: string[]): boolean => {
  return (
    args[0]?.includes?.(
      "Warning: findDOMNode is deprecated and will be removed in the next major release.",
    ) &&
    args[1]?.includes?.("rc-resize-observer") &&
    args[1]?.includes?.("rc-tooltip")
  );
};

/**
 * TODO: after every updrage of react-scroll-sync, double check if that error still occurs
 * version of react-scroll-sync during last check: ^0.10.0
 */
const isFindDOMNodeErrorFromReactScrollSync = (args: string[]): boolean => {
  return (
    args[0]?.includes?.(
      "Warning: findDOMNode is deprecated and will be removed in the next major release.",
    ) && args[1]?.includes?.("react-scroll-sync")
  );
};

/**
 * TODO: after every updrage of react-scroll-sync, double check if that error still occurs
 * version of react-scroll-sync during last check: ^0.10.0
 */
const isScrollSyncPaneFromReactScrollSync = (args: string[]): boolean => {
  return (
    args[1]?.includes?.("ScrollSyncPane") &&
    args[2]?.includes("react-scroll-sync")
  );
};

/**
 * TODO: after every updrage of @mui/material, double check if that error still occurs
 * version of @mui/material during last check: ^5.16.14
 */
const isNotchedErrorFromMuiMaterial = (args: string[]): boolean => {
  return (
    args[0]?.includes?.(
      "Warning: Received `%s` for a non-boolean attribute `%s`",
    ) &&
    args[2]?.includes?.("notched") &&
    args[6]?.includes?.("@mui/material")
  );
};

const originalConsoleError = console.error;
console.error = (...args) => {
  if (
    !isFindDOMNodeErrorFromRCTooltip(args) &&
    !isFindDOMNodeErrorFromReactScrollSync(args) &&
    !isScrollSyncPaneFromReactScrollSync(args) &&
    !isNotchedErrorFromMuiMaterial(args)
  ) {
    originalConsoleError(...args);
  }
};
