import styled from "styled-components";

import type { BoxProps } from "../Box";
import Box from "../Box";

export type FlexProps = BoxProps & {
  gap?: string;
};

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${({ gap }) => (gap ? `gap: ${gap};` : "")}
`;

export { BoxProps, Box, Flex };

export default Flex;
