import React from "react";
import { useTranslation } from "react-i18next";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { ThemeProvider } from "styled-components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { deDE, enUS } from "@mui/x-date-pickers/locales";
//TODO: resolve toast rendering issue when language is changed: https://github.com/fkhadra/react-toastify/issues/148
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { StyleSheetManager } from "styled-components";

import ReactQueryProvider from "Shared/ReactQuery/Provider";
import theme from "Shared/theme";
import muiTheme from "Shared/muiTheme";
import ViewportProvider from "Shared/Viewport/Provider";
import SessionProvider from "Shared/Session/Provider";
import RootApp from "./RootApp";
import useShouldForwardProp from "./hooks/useShouldForwardProp";

type LocaleMap = {
  [x: string]: Locale;
};

type LocaleTextMap = {
  [x: string]: Record<string, any>;
};

const localeMap: LocaleMap = {
  en: {
    ...enLocale,
    options: {
      ...enLocale.options,
      weekStartsOn: 1,
    },
  },
  de: deLocale,
};

const localeTextMap: LocaleTextMap = {
  en: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  de: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
};

const Root: React.FC = () => {
  const { i18n } = useTranslation();
  const shouldForwardProp = useShouldForwardProp();

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeMap[i18n.language]}
          localeText={localeTextMap[i18n.language]}
        >
          <ViewportProvider>
            <SessionProvider>
              <ReactQueryProvider>
                <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                  <Router
                    basename={process.env.REACT_APP_ROUTE_PREFIX}
                    getUserConfirmation={() => {
                      /* Empty callback to block the default browser prompt */
                    }}
                  >
                    <RootApp />
                  </Router>
                </StyleSheetManager>
              </ReactQueryProvider>
            </SessionProvider>
          </ViewportProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default Root;
